import { gql } from '@apollo/client';
import { client } from '../graphql-client';

const TOKEN_KEY = '__punctuality_token';
const ROLE = '__punctuality_role';

export const getToken = () => {
  const token = window.localStorage.getItem(TOKEN_KEY);
  return token;
};

export const getUserProfile = async () => {
  return !getToken()
    ? null
    : {
        name: '',
        email: '',
        imageUrl: '',
        role: window.localStorage.getItem(ROLE),
      };
};

export async function logout() {
  window.localStorage.removeItem(TOKEN_KEY);
  document.location.href = '/login';
}

const LOGIN = gql`
  mutation loginUser($input: LoginUserInput) {
    loginUser(input: $input) {
      token
      role
    }
  }
`;

export async function login({ email, password }) {
  const authResult = await client.mutate({
    mutation: LOGIN,
    variables: { input: { email, password } },
  });

  window.localStorage.setItem(TOKEN_KEY, authResult.data?.loginUser?.token);
  window.localStorage.setItem(ROLE, authResult.data?.loginUser?.role);

  return getToken();
}
