import React from 'react';
// @ts-ignore
// import classnames from 'clsx'
import { Spin } from 'antd';
import { LoadingProps } from './models/loadingProps';

function Loading(props: LoadingProps) {
  // const paperStyles = classnames('paper', className);
  const { children, loading = true, delay = 200, tip } = props;

  return (
    <Spin className="loading" spinning={loading} tip={tip} delay={delay}>
      {children}
    </Spin>
  );
}

export default Loading;
