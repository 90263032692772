import { gql, useQuery } from '@apollo/client';
import { DEPARTMENT_LIST } from 'const';
import differenceInDays from 'date-fns/differenceInDays'

export const GET_ME = gql`
  query me {
    me {
      email
      name
      deviceUserId
      department
      employeeCode
      entryDate
      role
    }
  }
`;

export function MyInfo() {
  const { loading, data: profile } = useQuery(GET_ME, {
    'fetchPolicy': 'network-only'
  });
  const now = new Date()

  const daysOfService = profile?.me?.entryDate
    ? differenceInDays(now, new Date(profile?.me?.entryDate))
    : 0;

  const years = Math.floor(daysOfService / 365);
  const days = daysOfService % 365;

  return (
    <div className="bg-white p-6 m-6 flex flex-col gap-6">
      {loading ? (
        'Loading...'
      ) : (
        <>
          <div>
            <h5 className="font-bold">Name</h5>
            <p>{profile.me.name}</p>
          </div>
          <div>
            <h5 className="font-bold">Employee Code</h5>
            <p>{profile.me.employeeCode}</p>
          </div>
          <div>
            <h5 className="font-bold">Fingerprint Device Id</h5>
            <p>{profile.me.deviceUserId}</p>
          </div>
          <div>
            <h5 className="font-bold">Department</h5>
            <p>{getDepartmentName(profile.me.department)}</p>
          </div>
          <div>
            <h5 className="font-bold">Entry date</h5>
            <p>
              {new Intl.DateTimeFormat(undefined, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }).format(new Date(profile.me.entryDate))}
            </p>
          </div>
          <div>
            <h5 className="font-bold">Year of service</h5>
            <p>{`${daysOfService} days (${years} years and ${days} days)`}</p>
          </div>
        </>
      )}
    </div>
  );
}

const getDepartmentName = departmentCode => {
  const department = DEPARTMENT_LIST.find(item => item.value === departmentCode)?.name || 'N/A'

  return department
}