import React from 'react';
// @ts-ignore
import classnames from 'clsx';

interface PaperProps {
  children?: any;
  className?: string;
}

function Paper({ children, className }: PaperProps) {
  const paperStyles = classnames('paper', className);
  return <div className={paperStyles}>{children}</div>;
}

export default Paper;
