import { useAuth } from 'components/auth';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Clock from './../components/clock';
import GoogleBtn from './../components/googleBtn';

function Login(props: any) {
  const [submitting, setSubmitting] = useState(false);
  const { login } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const onLoginSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault();

    const email = e.currentTarget.elements['employeeEmail'].value;
    const password = e.currentTarget.elements['employeePassword'].value;

    try {
      setSubmitting(true);
      await login({ email, password });

    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  };

  const showStandardLogin =
    process.env.REACT_APP_AUTH_PROVIDER === 'Standard' ||
    process.env.REACT_APP_AUTH_PROVIDER === 'Both';
  
  const showGoogleLogin =
    process.env.REACT_APP_AUTH_PROVIDER === 'Google' ||
    process.env.REACT_APP_AUTH_PROVIDER === 'Both';

  return (
    <div className="login">
      <Clock className="login__digital__clock" />
      <div className="login__wrapper">
        <h1 className="login__title">ekino.</h1>
        <p className="login__subtitle">Auto Punctuality Checker</p>
        <div className="flex flex-col divide-y max-w-md">
          {showStandardLogin && (
            <form
              className="grid grid-cols-1 gap-4 mt-3 mb-7"
              onSubmit={onLoginSubmit}
            >
              <label className="block">
                <span className="text-slate-100">Email address</span>
                <input
                  type="email"
                  name="employeeEmail"
                  className="
                  block
                  w-full
                  rounded
                  bg-white
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                  "
                />
              </label>
              <label className="block">
                <span className="text-slate-100">Password</span>
                <input
                  type="password"
                  name="employeePassword"
                  className="
                  block
                  w-full
                  rounded
                  bg-white
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                  "
                />
              </label>
              <button
                disabled={submitting}
                className="bg-green-600 py-2 px-4 rounded text-white font-semibold mt-2 text-lg"
                type="submit"
              >
                {submitting === true ? 'Loading' : 'Log In'}
              </button>
            </form>
          )}

          {showGoogleLogin && (
            <div className="pt-5">
              <GoogleBtn className="login__button" history={props.history} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
