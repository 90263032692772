import React from 'react';
import { Form, Input, Button, notification, Modal } from 'antd';

import Loading from '../../components/loading';
import { emailValidator } from '../../utils/emailValidator';
import { AddUserModalProps } from '../models/AddUserModalProps';

export function AddUserModal(props: AddUserModalProps) {
  const { onCancel, addUser, shouldDisplayAddModal, loading } = props;

  const onAdd = (values: any) => {
    if (!values.errorFields?.length) {
      addUser({ variables: values });
    }
  };

  const onAddFailed = () => {
    notification.error({
      message: 'Add user form is not valid! Please correct them!',
    });
  };

  return (
    <Modal
      title="Add User"
      visible={shouldDisplayAddModal}
      footer={null}
      onCancel={onCancel}
    >
      <Loading loading={loading}>
        <Form name="basic" onFinish={onAdd} onFinishFailed={onAddFailed}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (emailValidator(value)) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      'Please enter a valid email! (Ekino or Bollore staff only)'
                    )
                  );
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              className="add-user-submit"
              disabled={loading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  );
}
