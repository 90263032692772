import { EmployeeDaily } from './pages/models/EmployeeDaily';

export function formatDataDaily(dailyEmployee: any): EmployeeDaily[] {
  return dailyEmployee.map((item: any) => {
    const statusAM = item.statusAM;
    const statusPM = item.statusPM;
    return {
      employeeCode: item.employee.employeeCode,
      fullName: item.employee.fullName,
      status: `${statusAM}${statusPM}`,
      statusArr: [statusAM, statusPM],
      id: item.employee.id,
      checkTimeAM: formatDate(item.checkTimeAM),
      checkTimePM: formatDate(item.checkTimePM),
      employeeWorkFromHomeShifts: item.employeeWorkFromHomeShifts
    };
  });
}

const formatDate = (date: Date | string | number) => {
  return date
    ? new Date(date)
        .toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        })
        .slice(0, 5)
    : '';
};

export function formatDataRange(dailyEmployee: any) {
  return dailyEmployee.map((item: any) => {
    return {
      employeeCode: item.employee.employeeCode,
      fullName: item.employee.fullName,
      present: item.present,
      dateOff: item.dateOff,
      late: item.late,
      absent: item.absent,
      workFromHome: item.workFromHome
    };
  });
}
export function filterData(data: any, filterObject: any) {
  let array: any[] = [];
  for (const item in filterObject) {
    const dataToFilter = array.length ? array : data;
    for (const child of filterObject[item]) {
      const arrayItem =
        item === 'statusArr'
          ? dataToFilter.filter((d: any) => d[item].includes(child))
          : dataToFilter.filter((d: any) => d[item].indexOf(child) > -1);
      if (arrayItem.length) {
        array = array.concat(arrayItem);
      }
    }
  }
  return array;
}

export function combineStatus(statusA: string[], statusP: string[]) {
  let statusCombined = statusA.concat(statusP);
  for (const item of statusA) {
    for (const item2 of statusP) {
      const combined = item + item2;
      statusCombined = [...statusCombined, combined];
    }
  }
  return statusCombined;
}

export function deleteAllCookies(document: any) {
  var cookies = document.cookie.split(';');
  console.log(cookies, 'cookies');
  console.log(document.cookie, 'cookies');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}
