import { GOOGLE_AUTH_CLIENT_ID, REACT_APP_GRAPHQL_SERVER } from 'const';
import { gql, GraphQLClient } from 'graphql-request';

const CALLBACK_NAME = '__initGoogleAuthCallback';
const TOKEN_KEY = '__punctuality_token';
const ROLE = '__punctuality_role';

export const getToken = () => {
  const token = window.localStorage.getItem(TOKEN_KEY);
  return token;
};

let promise = null;

const GOOGE_AUTH_LOAD_TIMEOUT = 10000

function loadGoogleAuthentication(options) {
  promise =
    promise ||
    new Promise(function (resolve, reject) {
      const timeoutId = setTimeout(function () {
        window[CALLBACK_NAME] = function () {};
        reject(new Error('Could not load the Google Auth'));
      }, options.timeout || GOOGE_AUTH_LOAD_TIMEOUT);

      window[CALLBACK_NAME] = function () {
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
        }
        const gapi = (window as any).gapi;
        let auth = null;
        gapi.load('auth2', function () {
          auth = gapi.auth2.init({
            client_id: GOOGLE_AUTH_CLIENT_ID,
            cookie_policy: 'single_host_origin',
          });
          resolve(auth);
        });
        delete window[CALLBACK_NAME];
      };

      const script = document.createElement('script');
      script.src = `https://apis.google.com/js/platform.js?onload=${CALLBACK_NAME}`;
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  return promise;
}

export const getUserProfile = async () => {
  const GoogleAuth = await loadGoogleAuthentication({});
  const currentUser = GoogleAuth.currentUser.get();
  const basicProfile = currentUser.getBasicProfile();
  return (
    basicProfile && {
      email: basicProfile?.getEmail(),
      imageUrl: basicProfile?.getImageUrl(),
      name: basicProfile?.getName(),
      role: window.localStorage.getItem(ROLE),
    }
  );
};

export async function logout() {
  const ggAuth = await loadGoogleAuthentication({});
  ggAuth.signOut();
  localStorage.removeItem(TOKEN_KEY);
  document.location.href = '/login';
}

const CHECKING_TOKEN = gql`
  mutation login($token: String!) {
    loginGoogleUser(input: { token: $token }) {
      token
      role
    }
  }
`;

/**
 * Verify ID Token from Google
 * @param token ID Token
 * @returns
 */
const loginGoogleUser = (token) => {
  const graphqlClient = new GraphQLClient(REACT_APP_GRAPHQL_SERVER);
  return graphqlClient.request(CHECKING_TOKEN, { token });
};

export async function login() {
  const ggAuth = await loadGoogleAuthentication({});
  const googleUser = await ggAuth.signIn();

  try {
    const verifiedResult = await loginGoogleUser(
      googleUser.getAuthResponse().id_token
    );

    const idToken = verifiedResult.loginGoogleUser.token;
    const role = verifiedResult.loginGoogleUser.role;

    localStorage.setItem(TOKEN_KEY, idToken);
    localStorage.setItem(ROLE, role);

    return idToken;
  } catch (err) {
    console.log('🚀 Google Sign-In failed', err.response.errors[0].message);
  }
}
