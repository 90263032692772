import { gql } from '@apollo/client';
import { CORE_USER_FIELDS, CORE_USER_RESPONSE_FIELDS } from './fragment';

// https://www.apollographql.com/docs/react/api/react/hooks/#uselazyquery
export const USER_LOGIN = gql`
  query UserLogin($email: String!, $password: String!) {
    loginUser(input: { email: $email, password: $password }) {
      token
    }
  }
`;

export const GET_ME = gql`
  query me {
    me {
      email
      imageUrl
      lastName
      firstName
      role
    }
  }
`;

export const SET_EMPLOYEE = gql`
  mutation updateEmployee(
    $id: Int!
    $fullName: String
    $activated: Boolean
    $department: String
    $email: String
    $employeeCode: String
    $entryDate: Date
  ) {
    updateEmployee(
      input: {
        id: $id
        fullName: $fullName
        activated: $activated
        department: $department
        email: $email
        employeeCode: $employeeCode
        entryDate: $entryDate
      }
    ) {
      message
      status
      code
    }
  }
`;

export const GET_ALL_USER = gql`
  query getUsers {
    getUsers {
      ...CoreUserFields
    }
  }
  ${CORE_USER_FIELDS}
`;

export const ADD_USER = gql`
  mutation registerUser($email: String!) {
    registerUser(input: { email: $email }) {
      ...CoreUserResponseFields
    }
  }
  ${CORE_USER_RESPONSE_FIELDS}
`;

export const DELETE_USER = gql`
  mutation deleteUser($email: String!) {
    deleteUser(input: { email: $email }) {
      ...CoreUserResponseFields
    }
  }
  ${CORE_USER_RESPONSE_FIELDS}
`;
