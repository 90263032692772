/* eslint-disable no-debugger */
import clsx from 'clsx';
import gql from 'graphql-tag';
import { useEffect, useRef, useState } from 'react';
import { client } from '../../graphql-client';

// TODO: refactor gql
const UPDATE_EMAIL = gql`
  mutation updateEmployee(
    $id: Int!
    $fullName: String!
    $activated: Boolean
    $department: String
    $email: String
  ) {
    updateEmployee(
      input: {
        id: $id
        fullName: $fullName
        activated: $activated
        department: $department
        email: $email
      }
    ) {
      message
      status
      code
    }
  }
`;

const updateEmail = async ({ id, email, fullName, activated, department }) => {
  await new Promise((res) => setTimeout(res, 200));
  return client.mutate({
    mutation: UPDATE_EMAIL,
    variables: {
      id: +id,
      email,
      fullName,
      activated,
      department,
    },
  });
};

const regexEmail =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

export const EmailInput = ({
  id,
  fullName,
  originalEmail,
  activated,
  department,
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const [email, setEmail] = useState(originalEmail);
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    setValidationErrors([])
  };

  const [saving, setSaving] = useState(false);

  const save = async () => {
    try {
      const updated =
        inputRef.current?.value || generateEmailSuggestion(fullName);

      if (!new RegExp(regexEmail).test(updated)) {
        return setValidationErrors(['Invalid email'])
      }
      setSaving(true);
      await updateEmail({
        id,
        fullName,
        email: updated,
        activated,
        department,
      });
      setSaving(false);
      setEmail(updated);
      setEditing(false);
      setValidationErrors([])
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
    }
  };

  const onButtonClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    save();
  };

  const btnRef = useRef();

  return (
    <div>
      <form>
        {editing ? (
          <input
            className="ant-input"
            ref={inputRef}
            onBlur={() => {
              setTimeout(() => {
                // console.log(document.activeElement);
                // console.log(btnRef.current);
                if (document.activeElement !== btnRef.current) {
                  setEditing(false);
                }
              });
            }}
            defaultValue={email || generateEmailSuggestion(fullName)}
          />
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{ paddingRight: 24 }}
            onClick={toggleEdit}
          >
            {email || (
              <span
                className={clsx(
                  'flex gap-3 items-center justify-between flex-wrap',
                  {
                    'text-gray-400': !email,
                  }
                )}
              >
                <div className="truncate">
                  {generateEmailSuggestion(fullName) || ''}
                </div>
              </span>
            )}
          </div>
        )}
        {!email || editing ? (
          <button
            ref={btnRef}
            className={clsx(
              'bg-green-500 py-1 px-2 rounded text-white shadow disabled:opacity-70 focus:border-2'
            )}
            disabled={saving}
            onClick={(e) => {
              e.preventDefault();
              onButtonClickHandler(e);
            }}
          >
            {saving ? 'Saving' : !email ? 'Confirm this email' : 'Save'}
          </button>
        ) : null}
        {validationErrors.length ? (
          <span className="text-red-500">{validationErrors}</span>
        ) : null}
      </form>
    </div>
  );
};

function generateEmailSuggestion(fullName: string) {
  const lastName = fullName.match(/\b([A-Z]+)\b/g);
  const firstName = fullName.match(/\b([A-Z][a-z]+)\b/g);

  return lastName && firstName
    ? `${firstName.map((part) => part.toLowerCase()).join('-')}.${lastName
        .map((part) => part.toLowerCase())
        .join('-')}@ekino.com`
    : 'Cannot generate suggestion.';
}
