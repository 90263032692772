import { useLazyQuery } from '@apollo/client';
import {
  Button,
  DatePicker,
  Pagination,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { useAuth } from 'components/auth';
import { AdminOnly } from 'components/admin-only';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import {
  API_ENDPOINT,
  DATE_DISPLAY_FORMAT,
  DATE_QUERY_FORMAT,
  RECORD_PER_PAGE,
} from '../const';
import {
  GET_DAILY_EXPORT_ID,
  GET_EMPLOYEES_DAILY_QUERY,
} from '../graphql/employee';
import EmployeeSearchBar from './../components/employeeSearchBar';
import Loading from './../components/loading';
import Paper from './../components/paper';
import { combineStatus, formatDataDaily } from './../utils';
import { EmployeeDaily } from './models/EmployeeDaily';

type statusColor = {
  [key: string]: string;
};
type statusTitle = {
  [key: string]: string;
};

const statusACouldHave = ['AO', 'A', 'AA', 'AB'];
const statusPCouldHave = ['PO', 'P', 'PA', 'PB'];
const statusCombined = combineStatus(statusACouldHave, statusPCouldHave);

function Daily(props: any) {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataDisplay, setData] = useState<any[]>(dataSource);
  const [isData, setIsData] = useState(false);
  const [date, setDate] = useState('');
  const [valueDate, setValueDate] = useState('');
  const [pagination, setPage] = useState<any>({});
  const [queryInput, setQueryInput] = useState<any>({
    status: '',
    name: '',
  });

  const [getEmployeesDaily, { loading, data: employeesDaily }] = useLazyQuery(
    GET_EMPLOYEES_DAILY_QUERY,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getDateExportId, { data: idExport }] =
    useLazyQuery(GET_DAILY_EXPORT_ID);
  const statusColor: statusColor = {
    AO: 'green',
    A: 'geekblue',
    AA: 'purple',
    AB: 'volcano',
    PO: 'green',
    P: 'geekblue',
    PA: 'purple',
    PB: 'volcano',
  };
  const statusTitle: statusTitle = {
    AO: 'On-time morning',
    A: 'Late after 9:15',
    AA: 'Absent cause late after 10:00',
    AB: 'Morning absent unplanned',
    PO: 'On-time afternoon',
    P: 'Late after 14:15',
    PA: 'Absent cause late after 15:00',
    PB: 'Afternoon absent unplanned',
    AU: 'Morning not recorded yet',
    PU: 'Afternoon not recorded yet',
  };
  useEffect(() => {
    const current = moment();
    const queryDate = props.match.params.date;
    if (queryDate && current >= moment(queryDate, DATE_QUERY_FORMAT)) {
      const dateFormat = moment(
        props.match.params.date,
        DATE_QUERY_FORMAT
      ).format('DD MMM, YYYY');
      const dateFormatApi = moment(
        props.match.params.date,
        DATE_QUERY_FORMAT
      ).format(DATE_QUERY_FORMAT);
      setQueryInput((q: any) => ({
        ...q,
        date: dateFormatApi,
        page: 1,
        limit: RECORD_PER_PAGE,
      }));
      setDate(dateFormat);
    } else {
      props.history.push(`/daily/${moment().format(DATE_QUERY_FORMAT)}`);
    }
  }, [props]);
  useEffect(() => {
    if (queryInput && queryInput.date) {
      getEmployeesDaily({ variables: queryInput });
      getDateExportId({
        variables: {
          date: queryInput.date,
        },
      });
    }
  }, [getEmployeesDaily, queryInput, getDateExportId]);

  useEffect(() => {
    if (employeesDaily) {
      const { getDailyEmployees } = employeesDaily;
      const newData = formatDataDaily(getDailyEmployees.data);
      const pagi = getDailyEmployees.metaData;
      setPage({
        current: queryInput.page,
        pageSize: RECORD_PER_PAGE,
        total: pagi.numOfPage * RECORD_PER_PAGE,
      });
      setDataSource(newData);
      setIsData(true);
    }
  }, [employeesDaily, queryInput]);

  useMemo(() => {
    if (dataSource) {
      setData(dataSource);
    }
  }, [dataSource]);

  const handleOnChangeDate = (date: any, dateString: any) => {
    if (date) {
      setValueDate(
        moment(dateString, DATE_DISPLAY_FORMAT).format(DATE_QUERY_FORMAT)
      );
    } else {
      setValueDate('');
    }
  };

  const handleOnClick = () => {
    props.history.push(`/daily/${valueDate}`);
  };

  const handleExport = async () => {
    const uri = `${API_ENDPOINT}/employee/export?exportId=${idExport.getDateExportId.exportId}`;
    window.location.href = uri;
    // window.open(uri);
  };

  const handleSelect = (value: any) => {
    const query = value.length ? value.join('|') : '';
    setQueryInput({
      ...queryInput,
      name: query,
      page: 1,
    });
  };
  const handleSelectStatus = (value: any) => {
    const query = value.length ? value.join('|') : '';
    setQueryInput({
      ...queryInput,
      status: query,
      page: 1,
    });
  };

  const renderOptionsStatus = () => {
    // @ts-ignore
    const uniq = [...new Set(statusCombined)];
    return uniq.sort().map((a) => ({ value: a }));
  };

  const renderTagStatus = (props: any) => {
    const { label, value, closable, onClose } = props;
    return (
      <Tag
        color={statusColor[value]}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
  const handlePaginate = (value: number, pageSize: any) => {
    setQueryInput({
      ...queryInput,
      page: value,
    });
    setPage({
      ...pagination,
      current: value,
    });
  };

  const getClassNameRowTable = (record: EmployeeDaily, index: number) => {
    const className = ['daily__table__row'];
    const lateCode = ['A', 'AA', 'P', 'PA'];
    const absentCode = ['AB', 'PB'];
    const isLate = record.statusArr.find((status: string) =>
      lateCode.includes(status)
    );
    const isAbsent = record.statusArr.find((status: string) =>
      absentCode.includes(status)
    );

    if (isAbsent) {
      className.push('is-absent');
    }

    if (isLate) {
      className.push('is-late');
    }

    if (index % 2 === 0) {
      className.push('daily__table-row-light');
    } else {
      className.push('daily__table-row-dark');
    }

    return className.join(' ');
  };

  const columns = [
    {
      title: () => <div className="daily__table__title">Employee Code</div>,
      dataIndex: 'employeeCode',
      key: 'employeeCode',
      width: '30%',
    },
    {
      title: () => (
        <div className="daily__table__title">
          FULL NAME
          <AdminOnly>
            <EmployeeSearchBar
              className="daily__table__search"
              handleSelect={handleSelect}
            />
          </AdminOnly>
        </div>
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      width: '70%',
    },
    {
      title: () => <div className="daily__table__title">AM</div>,
      dataIndex: 'checkTimeAM',
      key: 'checkTimeAM',
      width: '30%',
    },
    {
      title: () => <div className="daily__table__title">PM</div>,
      dataIndex: 'checkTimePM',
      key: 'checkTimePM',
      width: '30%',
    },
    {
      title: () => (
        <div className="daily__table__title">
          Status
          <AdminOnly>
            <Select
              className="daily__table__search--status"
              mode="multiple"
              tagRender={renderTagStatus}
              placeholder={`Search Status`}
              onChange={handleSelectStatus}
              options={renderOptionsStatus()}
            />
          </AdminOnly>
        </div>
      ),
      dataIndex: 'statusArr',
      key: 'statusArr',
      width: '30%',
      render: (statusArr: any, data) => {
        return (
          <>
            {statusArr.map((tag: string, index: number) => {
              let color = statusColor[tag];
              let title = statusTitle[tag];
              if (index === 2) {
                return null;
              }
              const workFromHomeShifts = data.employeeWorkFromHomeShifts[index];

              return (
                <Tooltip title={title} color={color} key={index}>
                  <Tag color={color} key={index} title={title}>
                    {tag.toUpperCase()}
                    {workFromHomeShifts && ` (WFH)`}
                  </Tag>
                </Tooltip>
              );
            })}
          </>
        );
      },
    },
  ];

  return (
    <div className="daily">
      {/* <PushNotification /> */}
      <Paper>
        <div className="daily__title">
          Date: <span className="daily__date">{date}</span>
        </div>
        <div className="daily__utils">
          <div className="daily__utils__left">
            <div className="daily__input__date">
              <DatePicker
                placeholder="Pick a date"
                onChange={handleOnChangeDate}
                disabledDate={(current) => {
                  return (
                    current > moment().endOf('day') ||
                    current === moment().endOf('week')
                  );
                }}
                format={DATE_DISPLAY_FORMAT}
              />
            </div>
            <Button
              type="primary"
              disabled={
                !valueDate ||
                valueDate ===
                  moment(date, 'DD MMM, YYYY').format(DATE_QUERY_FORMAT)
                  ? true
                  : false
              }
              onClick={handleOnClick}
              id="daily-go"
            >
              Go
            </Button>
          </div>
          <AdminOnly>
            <div className="daily__utils__right">
              <Button
                type="primary"
                onClick={handleExport}
                disabled={dataSource && idExport ? false : true}
                id="daily-export"
              >
                Export Excel
              </Button>
            </div>
          </AdminOnly>
        </div>
        {!isData ? (
          <Loading />
        ) : (
          <>
            <Table
              className="daily__table"
              bordered={true}
              dataSource={dataDisplay}
              columns={columns}
              scroll={{ y: 0 }}
              loading={loading}
              pagination={false}
              rowKey={(record) => record.fullName}
              rowClassName={getClassNameRowTable}
            />
            <Pagination
              defaultCurrent={1}
              current={pagination.current}
              className="daily__pagination"
              total={pagination.total}
              showSizeChanger={false}
              pageSize={RECORD_PER_PAGE}
              defaultPageSize={RECORD_PER_PAGE}
              onChange={handlePaginate}
            />
          </>
        )}
      </Paper>
    </div>
  );
}

export default Daily;
