import { gql } from '@apollo/client';

export const GET_EMPLOYEES_DAILY_QUERY = gql`
  query getEmployeeDailyQuery(
    $date: String!
    $page: Int!
    $limit: Int!
    $status: String!
    $name: String!
  ) {
    getDailyEmployees(
      input: {
        date: $date
        page: $page
        limit: $limit
        status: $status
        name: $name
      }
    ) {
      data {
        statusAM
        checkTimeAM
        checkTimePM
        statusPM
        employeeWorkFromHomeShifts {
          shift
          date
        }
        employee {
          employeeCode
          fullName
          id
          activated
        }
      }
      metaData {
        numOfPage
        limit
      }
    }
  }
`;
export const GET_RANGE_QUERY = gql`
  query getEmployeeRangeQuery(
    $from: String!
    $to: String!
    $page: Int!
    $limit: Int!
    $name: String!
  ) {
    getRangeEmployees(
      input: { from: $from, to: $to, page: $page, limit: $limit, name: $name }
    ) {
      data {
        present
        dateOff
        late
        absent
        workFromHome
        employee {
          fullName
          employeeCode
          id
          activated
        }
      }
      metaData {
        numOfPage
        limit
      }
    }
  }
`;
export const GET_ALL_EMPLOYEE = gql`
  query getEmployees($page: Int!, $limit: Int!, $name: String!) {
    getEmployees(input: { page: $page, limit: $limit, name: $name }) {
      data {
        fullName
        id
        activated
        department
        email
        employeeCode
        deviceUserId
        raw_password
        entryDate
      }
      metaData {
        numOfPage
        limit
      }
    }
  }
`;

export const GET_DAILY_EXPORT_ID = gql`
  query getDateId($date: String!) {
    getDateExportId(input: { date: $date }) {
      exportId
    }
  }
`;

export const GET_RANGE_EXPORT_ID = gql`
  query getRangeExportId($from: String!, $to: String!) {
    getRangeExportId(input: { from: $from, to: $to }) {
      exportId
    }
  }
`;
