import Login from 'pages/login';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as AuthLib from '../authentication';

const defaultAuth =
  process.env.REACT_APP_AUTH_PROVIDER === 'Standard'
    ? AuthLib.standardAuth
    : AuthLib.ggAuth;

const getAuthProvider = (authProvider) => authProvider === 'Google' ? AuthLib.ggAuth : AuthLib.standardAuth

const AuthContext = React.createContext<null | {
  userProfile: any;
  login: any;
  logout: any;
}>(null);

const AUTHEN_STATUS = {
  loading: 'loading',
  authenticated: 'authenticated',
  unauthentiated: 'unauthentiated',
} as const;

export function Auth({ children }) {
  const [authenStatus, setAuthenStatus] = useState<keyof typeof AUTHEN_STATUS>(
    AUTHEN_STATUS.loading
  );
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    defaultAuth.getUserProfile().then((profile) => {
      if (profile) {
        setUserProfile(profile);
        setAuthenStatus(AUTHEN_STATUS.authenticated);
      } else {
        setAuthenStatus(AUTHEN_STATUS.unauthentiated);
      }
    });
  }, []);

  const login = async (userAuth, authProvider = '') => {
    const auth = getAuthProvider(authProvider)
    const result = await auth.login(userAuth);
    if (result) {
      const profile = await auth.getUserProfile();
      setUserProfile(profile);
      setAuthenStatus(AUTHEN_STATUS.authenticated);
    }
  };

  const logout = () => {
    Promise.all([(AuthLib.ggAuth.logout(), AuthLib.standardAuth.logout())]).then(
      () => {
        setAuthenStatus(AUTHEN_STATUS.unauthentiated);
      }
    );
  };

  return (
    <AuthContext.Provider
      value={{
        userProfile,
        login,
        logout,
      }}
    >
      {authenStatus === AUTHEN_STATUS.loading ? null : authenStatus ===
        AUTHEN_STATUS.authenticated ? (
        children
      ) : (
        <Redirect to="/login" />
      )}

      <Switch>
        <Route path="/login">
          <Login />
        </Route>
      </Switch>
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
