import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button } from 'antd';
import { useGlobalState } from '../state';

export default function NotifModal({ text }: any) {
  const [contentNotif, setContent] = useGlobalState('contentNotif');
  const [isNotif, setIsNotif] = useGlobalState('isNotifModal');
  const handleCancel = () => {
    setIsNotif(false);
    setContent('');
  };
  return ReactDOM.createPortal(
    <Modal
      visible={isNotif}
      onOk={handleCancel}
      closable={false}
      onCancel={handleCancel}
      centered
      maskClosable={true}
      footer={null}>
      <div className="notif_modal">
        <p>{contentNotif}</p>
        <div className="notif__btn__wrapper">
          <Button type="primary" onClick={handleCancel}>
            OK
          </Button>
        </div>
      </div>
    </Modal>,
    document.body
  );
}
