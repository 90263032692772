import * as authLibs from '../authentication';
import { REACT_APP_GRAPHQL_SERVER } from 'const';
import { GraphQLClient } from 'graphql-request';

const graphQLClient = new GraphQLClient(REACT_APP_GRAPHQL_SERVER);

const auth = process.env.REACT_APP_AUTH_PROVIDER === 'Standard' ? authLibs.standardAuth : authLibs.ggAuth

export const requestGQL = async (query, variables) => {
  const token = auth.getToken();
  const requestHeaders = {
    Authorization: `Bearer ${token}`,
  };

  return graphQLClient.request(query, variables, requestHeaders);
};
