import React, { useState, useEffect } from 'react';
import { GET_ALL_EMPLOYEE } from '../graphql/employee';
import { useLazyQuery } from '@apollo/client';
import { Select } from 'antd';

const { Option } = Select;

interface Props {
  handleSelect: (value: any) => void;
  className?: string;
}

export default function EmployeeSearcBar({ handleSelect, className }: Props) {
  const [allEmployee, setAllEmployee] = useState<any>();
  const [getEmployees, { data: allEmployeesData }] =
    useLazyQuery(GET_ALL_EMPLOYEE);
  useEffect(() => {
    getEmployees({ variables: { page: 1, limit: 1000, name: '' } });
  }, [getEmployees]);

  useEffect(() => {
    if (allEmployeesData) {
      const { getEmployees } = allEmployeesData;
      setAllEmployee(getEmployees.data);
    }
  }, [allEmployeesData]);
  const renderOptions = () => {
    if (allEmployee && allEmployee.length) {
      return allEmployee.map((a: any, i: number) => {
        return (
          <Option key={i} value={a.fullName}>
            {a.fullName}
          </Option>
        );
      });
    }
  };
  return (
    <Select
      className={className}
      mode="tags"
      placeholder={`Search FullName`}
      onChange={handleSelect}
    >
      {renderOptions()}
    </Select>
  );
}
