import 'antd/dist/antd.css';
import { Auth } from 'components/auth';
import moment from 'moment';
import React from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch
} from 'react-router-dom';
import DrawerMenu from './components/drawer';
import Navbar from './components/navbar';
import NotifModal from './components/notifModal';
import { DATE_QUERY_FORMAT } from './const';
import { routes } from './routes';
import './scss/main.scss';
import { useGlobalState } from './state';

function AppLayout({ children }) {
  return (
    <div className="app_wrapper">
      <Navbar pageName={'PAGE NAME'} />
      <DrawerMenu />
      <div className="content_wrapper">{children}</div>
    </div>
  );
}

function AppRoutes() {
  const [isNotif] = useGlobalState('isNotifModal');

  const defaultDay = moment().format(DATE_QUERY_FORMAT);

  const renderRoute = () => {
    return routes.map((item, i) => {
      const Component = item.component;
      return (
        <Route key={i} path={item.path} exact={item.exact} component={Component} />
      );
    });
  };

  return (
    <>
      <Switch>
        <AppLayout>
          {renderRoute()}
          <Route
            path="/"
            render={(propsComponent) => {
              return (
                <Redirect {...propsComponent} to={`/daily/${defaultDay}`} />
              );
            }}
          />
        </AppLayout>
      </Switch>
      {isNotif && <NotifModal />}
    </>
  );
}

function App() {
  return (
    <Router>
      <Auth>
        <AppRoutes />
      </Auth>
    </Router>
  );
}

export default App;
