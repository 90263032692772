export const API_ENDPOINT = 'https://koa.pc.ekinoffy.com';
export const RECORD_PER_PAGE = 25;
export const REACT_APP_GRAPHQL_SERVER = `https://koa.pc.ekinoffy.com/graphql`;
export const PUSH_SERVER_PUBLIC_KEY =
  'BJIH9TMOpkq7cktZJQ1PTrS7fhlbFLstklNosr3r3tnPFYgj4PNZWVJvxrqwlGMF9ElRsOu7ccLMD_bBB07snfQ';
export const GOOGLE_AUTH_CLIENT_ID = '144462708524-tc078bh4b231ks713flbkihtbk6hh0go.apps.googleusercontent.com';
export const DATE_QUERY_FORMAT = 'YYYY-MM-DD';
export const DATE_DISPLAY_FORMAT = 'DD MMM YYYY';
export const DEPARTMENT_LIST = [
  {
    name: 'Frontend (FEA)',
    value: 'frontend-fea',
  },
  {
    name: 'Frontend (FED)',
    value: 'frontend-fed',
  },
  {
    name: 'Backend (Java)',
    value: 'java',
  },
  {
    name: 'Backend (PHP)',
    value: 'php',
  },
  {
    name: 'Backend (.NET)',
    value: 'dotnet',
  },
  {
    name: 'Mobile (iOS)',
    value: 'mobile-ios',
  },
  {
    name: 'Mobile (Android)',
    value: 'mobile-android',
  },
  {
    name: 'Technical Project Manager (TPM)',
    value: 'project-manager',
  },
  {
    name: 'Tester (QC)',
    value: 'quality-control',
  },
  {
    name: 'Automation Tester (QC AUTO)',
    value: 'automation-tester',
  },
  {
    name: 'Admin',
    value: 'admin',
  },
  {
    name: 'Accounting (FA)',
    value: 'accounting',
  },
  {
    name: 'Personal assistant (PA)',
    value: 'personal-assistant',
  },
  {
    name: 'Webmaster Support Agent (WSA)',
    value: 'webmaster-support',
  },
  {
    name: 'Business Development (BD)',
    value: 'business-development',
  },
  {
    name: 'HR',
    value: 'hr',
  },
  {
    name: 'CEO',
    value: 'ceo',
  },
  {
    name: 'CTO',
    value: 'cto',
  },
  {
    name: 'Business Analytic (BA)',
    value: 'business-analytic',
  },
  {
    name: 'UX/UI Designer',
    value: 'ux-ui-designer',
  },
  {
    name: 'IT Helpdesk (IT)',
    value: 'it-helpdesk',
  },
  {
    name: 'BIS-NET',
    value: 'bis-net',
  },
  {
    name: 'BIS-EDI',
    value: 'bis-edi',
  },
  {
    name: 'BIS-QC',
    value: 'bis-qc',
  },
  {
    name: 'BIS-PM',
    value: 'bis-pm',
  },
  {
    name: 'BIS-SOLEX',
    value: 'bis-solex',
  },
  {
    name: 'BIS-SUPPORT',
    value: 'bis-support',
  },
  {
    name: 'BIS-DBA',
    value: 'bis-dba',
  },
  {
    name: 'ACVN',
    value: 'acvn',
  },
  {
    name: 'Legal',
    value: 'legal'
  },
  {
    name: 'Project Assistant',
    value: 'project-assistant'
  },
  {
    name: 'Adcity',
    value: 'adcity'
  }
].sort((a: any, b: any) =>
  a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })
);

export const USER_ROLE = {
  ADMIN: 'ADMIN',
  EMPLOYEE: 'EMPLOYEE'
}
