import { gql, useMutation, useQuery } from '@apollo/client';
import { DatePicker } from 'antd';
import addDays from 'date-fns/addDays';
import endOfWeek from 'date-fns/endOfWeek';
import getWeek from 'date-fns/getWeek';
import startOfWeek from 'date-fns/startOfWeek';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { requestGQL } from 'utils/requestGQL';
import { DAY, SHIFT, TIME } from './models/WorkFromHome';
import clsx from 'clsx';
import differenceInDays from 'date-fns/differenceInDays';
import { useAuth } from 'components/auth';
import { USER_ROLE } from 'const';

const now = new Date();

const REGISTER_WFH = gql`
  mutation WFHRegister($input: WorkFromHomeRegistrationInput!) {
    registerWorkFromHome(input: $input) {
      status
      registration {
        weekStr
        week {
          start
          end
        }
        employeeCode
        selectedShifts {
          shift
          date
        }
      }
    }
  }
`;

const QUERY_MY_ENTRY_DATE = gql`
  query me {
    me {
      email
      name
      deviceUserId
      entryDate
    }
  }
`;

export function WorkFromHome() {
  const { data } = useQuery(QUERY_MY_ENTRY_DATE);

  const [selectedWeek, setSelectedWeek] = useState({
    weekStr: `${new Date().getFullYear()}-${getWeek(addDays(new Date(), 7))}th`,
    week: {
      start: startOfWeek(addDays(new Date(), 7)),
      end: endOfWeek(addDays(new Date(), 7)),
    },
  });

  const onChange = (date, weekStr) => {
    setSelectedWeek({
      weekStr,
      week: {
        start: startOfWeek(new Date(date.toISOString())),
        end: endOfWeek(new Date(date.toISOString())),
      },
    });
  };

  const daysOfService = data?.me?.entryDate
    ? differenceInDays(now, new Date(data?.me?.entryDate))
    : 0;

  const years = Math.floor(daysOfService / 365);
  const days = daysOfService % 365;

  const maxWFHHalfDays = years < 1 ? 2 : 4;

  const auth = useAuth();
  const isAdmin = auth?.userProfile?.role === USER_ROLE.ADMIN;

  return (
    <>
      <div className="bg-white p-6 m-6 flex flex-col gap-6">
        <h4>
          Entry Date:{' '}
          <strong>
            {data && data?.me?.entryDate
              ? new Intl.DateTimeFormat(undefined, {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                }).format(new Date(data?.me?.entryDate))
              : 'N/A'}
          </strong>
        </h4>
        <div>
          <p>
            Your years of service is:{' '}
            <strong>
              {daysOfService} days ({years} years and {days} days)
            </strong>
            .
          </p>
          <p>
            You are allowed to declare <strong>{maxWFHHalfDays}</strong>{' '}
            half-days WFH per week.
          </p>
          <br />
          <p>
            You must declare before Thursday, 10 AM and declare for upcomming
            weeks.
          </p>
          <p>You cannot declare WFH for current week nor past weeks.</p>
        </div>
      </div>
      {data && (
        <div className="bg-white p-6 m-6 flex flex-col gap-6">
          <DatePicker
            disabledDate={isAdmin ? () => false : disabledDate}
            onChange={onChange}
            defaultValue={moment(selectedWeek.week.start)}
            picker="week"
          />
          <WorkFromHomeForm
            maxWFHHalfdays={maxWFHHalfDays}
            week={selectedWeek.week}
            weekStr={selectedWeek.weekStr}
            key={`week-${selectedWeek.weekStr}`}
          />
        </div>
      )}
    </>
  );
}

const QUERY_WFH_REGISTRATION = gql`
  query WFHRegister($week: String) {
    workFromHomeRegistration(week: $week) {
      weekStr
      week {
        start
        end
      }
      employeeCode
      selectedShifts {
        shift
        date
      }
    }
  }
`;

const getRegistration = async (weekStr) => {
  const promiseRes = requestGQL(QUERY_WFH_REGISTRATION, { week: weekStr });
  await new Promise((res, rej) => {
    setTimeout(res, 500);
  });
  return promiseRes;
};

function WorkFromHomeForm({
  weekStr,
  week,
  maxWFHHalfdays,
}: {
  week: { start: Date; end: Date };
  weekStr: string;
  maxWFHHalfdays: number;
}) {
  const [fetchingRegistration, setFetchingRegistration] = useState(false);

  useEffect(() => {
    let isMount = true
    setFetchingRegistration(true);
    getRegistration(weekStr).then(({ workFromHomeRegistration }) => {
      if (isMount) {
        if (!workFromHomeRegistration) {
          setFetchingRegistration(false);
          return;
        }

        const savedSelection = {};

        for (const shift of workFromHomeRegistration.selectedShifts) {
          savedSelection[shift.shift] = true;
        }

        setFetchingRegistration(false);
        setSelectedShifts({
          [weekStr]: savedSelection,
        });
      }
    });

    return () => {
      isMount = false
    }
  }, [weekStr]);

  const [selectedShifts, setSelectedShifts] = useState({
    [weekStr]: SHIFT,
  });

  const selectShifts = (e) => {
    const shift = e.target.value;
    const isSelected = e.target.checked;
    console.log(
      '🚀 ~ file: work-from-home.tsx ~ line 196 ~ selectShifts ~ isSelected',
      isSelected
    );

    console.log(
      '🚀 ~ file: work-from-home.tsx ~ line 203 ~ selectShifts ~ Object.values(selectShifts[weekStr] ?? {}).filter(Boolean).length',
      Object.values(selectedShifts[weekStr] ?? {}).filter(Boolean).length
    );
    if (
      Object.values(selectedShifts[weekStr] ?? {}).filter(Boolean).length >=
        maxWFHHalfdays &&
      isSelected
    ) {
      return;
    }

    setSelectedShifts({
      ...selectedShifts,
      [weekStr]: {
        ...selectedShifts[weekStr],
        [shift]: isSelected,
      },
    });

    result.reset();
  };

  const [registerWFH, result] = useMutation(REGISTER_WFH);

  const submitRegistration = (e) => {
    e.preventDefault();

    const filledForm = {
      week,
      weekStr,
      selectedShifts: Object.entries(selectedShifts[weekStr])
        .filter(([shift, isSelected]) => !!isSelected)
        .map(([shift]) => ({
          shift,
          date: getDateFromShift(week, shift as keyof typeof SHIFT),
        })),
    };

    registerWFH({
      variables: {
        input: filledForm,
      },
    });
  };

  return (
    <>
      <table className={clsx({ 'opacity-[0.5]': fetchingRegistration })}>
        <tbody>
          <tr>
            <td className="font-semibold pb-3">&nbsp;</td>
            <td className="font-semibold pb-3">Monday</td>
            <td className="font-semibold pb-3">Tuesday</td>
            <td className="font-semibold pb-3">Wednesday</td>
            <td className="font-semibold pb-3">Thursday</td>
            <td className="font-semibold pb-3">Friday</td>
          </tr>
          {TIME.map((time) => (
            <tr key={time}>
              <td className="w-[0.1%] whitespace-nowrap p-2">{time}</td>
              {DAY.map((day) => (
                <td key={day}>
                  <label>
                    <input
                      type="checkbox"
                      value={`${day}_${time}`}
                      disabled={fetchingRegistration || result.loading}
                      checked={
                        selectedShifts[weekStr]
                          ? Boolean(selectedShifts[weekStr][`${day}_${time}`])
                          : false
                      }
                      onChange={selectShifts}
                    />
                  </label>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-start">
        <button
          onClick={submitRegistration}
          disabled={result.loading || result.called || fetchingRegistration}
          className="bg-green-500 py-3 px-5 rounded text-white shadow disabled:opacity-70 leading-[100%]"
        >
          {result.called
            ? `Saved Registration For Week ${weekStr}`
            : `Submit Registration For Week ${weekStr}`}
        </button>
      </div>
    </>
  );
}

function disabledDate(current) {
  return current && current < moment().endOf('day');
}

function getDateFromShift(
  week: {
    start: Date;
    end: Date;
  },
  shift: keyof typeof SHIFT
) {
  const weekStart = week.start;

  let daysFromStart = 0;
  switch (shift) {
    case 'MON_AFTERNOON':
    case 'MON_MORNING':
      daysFromStart = 1;
      break;
    case 'TUE_AFTERNOON':
    case 'TUE_MORNING':
      daysFromStart = 2;
      break;
    case 'WED_AFTERNOON':
    case 'WED_MORNING':
      daysFromStart = 3;
      break;
    case 'THU_AFTERNOON':
    case 'THU_MORNING':
      daysFromStart = 4;
      break;
    case 'FRI_AFTERNOON':
    case 'FRI_MORNING':
      daysFromStart = 5;
      break;
  }

  return addDays(weekStart, daysFromStart);
}
