import React, { useState } from 'react';
import Paper from '../../components/paper';
import { Table, Popconfirm, Form, Input, Button, notification } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { GET_ALL_USER, ADD_USER, DELETE_USER } from '../../graphql/user/user';
import { useEffect } from 'react';
import EmployeeSearchBar from '../../components/employeeSearchBar';
import { RECORD_PER_PAGE } from '../../const';
import Loading from '../../components/loading';
import { AddUserModal } from './add-user';
import { User } from '../models/User';

function Users() {
  const {
    loading: getUserloading,
    data: allUserData,
    refetch,
  } = useQuery(GET_ALL_USER);

  const [addUser, { loading: addUserLoading }] = useMutation(ADD_USER, {
    update(
      cache,
      {
        data: {
          registerUser: { user, status, message },
        },
      }
    ) {
      if (!status) {
        notification.error({ message: `${user.email}: ${message}` });
        throw new Error(message);
      }

      cache.modify({
        fields: {
          getUsers(existingUserRefs = []) {
            setDisplayAddModal(false);
            notification.success({ message: `${user.email}: ${message}` });
            return [...existingUserRefs, user];
          },
        },
      });
    },
    onError(error) {
      return console.log(error);
    },
  });

  const [deleteUser, { loading: deleteUserLoading }] = useMutation(
    DELETE_USER,
    {
      update(
        cache,
        {
          data: {
            deleteUser: { user, status, message },
          },
        }
      ) {
        if (!status) {
          notification.error({ message: `${user.email}: ${message}` });
          throw new Error(message);
        }

        cache.modify({
          fields: {
            getUsers(existingUserRefs, { readField }) {
              notification.success({ message: `${user.email}: ${message}` });
              return existingUserRefs.filter(
                (emailRef: any) => user.email !== readField('email', emailRef)
              );
            },
          },
        });
      },
      onError(error) {
        return console.log(error);
      },
    }
  );

  const columns: any = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: '30%',
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: '30%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '40%',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: '20%',
      render: (text: string, record: User) => (
        <Popconfirm
          title={`Do you really want to delete ?`}
          onConfirm={() => deleteUser({ variables: { email: record.email } })}
        >
          <a>Delete</a>
        </Popconfirm>
      ),
    },
  ];

  let [shouldDisplayAddModal, setDisplayAddModal] = useState(false);

  return (
    <div className="employee">
      <Paper>
        <div className="daily__title">All Users</div>
        <div className="employee__add-wrapper">
          <Button
            onClick={() => setDisplayAddModal(!shouldDisplayAddModal)}
            className="employee__add"
          >
            Add +
          </Button>
        </div>
        <AddUserModal
          addUser={addUser}
          loading={addUserLoading}
          shouldDisplayAddModal={shouldDisplayAddModal}
          onCancel={() => setDisplayAddModal(!shouldDisplayAddModal)}
        />
        {getUserloading ? (
          <Loading />
        ) : (
          <Loading loading={deleteUserLoading}>
            <Table
              className="employee__table"
              dataSource={allUserData.getUsers}
              columns={columns}
              scroll={{ y: 0 }}
              loading={getUserloading}
              rowKey={(record) => record.email}
              bordered={true}
              pagination={false}
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? 'daily__table-row-light'
                  : 'daily__table-row-dark'
              }
            />
          </Loading>
        )}
      </Paper>
    </div>
  );
}

export default Users;
