import { PoweroffOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import * as authLibs from '../authentication';
import { useAuth } from './auth';

const auth = process.env.REACT_APP_AUTH_PROVIDER === 'true' ? authLibs.standardAuth : authLibs.ggAuth

export interface NavProps {
  pageName?: String;
}

function Navbar({ pageName }: NavProps) {
  const { userProfile } = useAuth();

  const renderUser = () => {
    if (userProfile === undefined) {
      return null;
    }
    return (
      <>
        <span className="navbar__user-name">{userProfile.name}</span>
        <img
          referrerPolicy="no-referrer"
          src={userProfile.imageUrl}
          className="navbar__avatar"
          alt="avatar"
        />
      </>
    );
  };

  const menu = (
    <Menu
      style={{ width: '120px', left: '50%', transform: 'translateX(-50%)' }}
    >
      <Menu.Item key="logout">
        <Link to="/" className="navbar__logout_dropdown" onClick={auth.logout}>
          <PoweroffOutlined className="navbar__logout__icon" />
          Log out
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <nav className={'navbar'}>
      <div className="navbar__wrapper">
        <div className="navbar__left">
          <p className="navbar__logo">ekino.</p>
        </div>
        {userProfile ? (
          <Dropdown overlay={menu} placement="bottomCenter" arrow>
            <div className="navbar__right">{renderUser()}</div>
          </Dropdown>
        ) : (
          ''
        )}
      </div>
    </nav>
  );
}

export default Navbar;
