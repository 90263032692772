import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useState } from 'react';

export const PasswordCell = ({ password }) => {
  const [shouldHidePass, setHidePassword] = useState(true);

  return (
    <div className="flex gap-2 items-center leading-0">
      {shouldHidePass ? (
        <div className="text-slate-500 whitespace-nowrap">{`Show password `}&rarr;</div>
      ) : (
        <div>{password}</div>
      )}
      <button
        className="leading-[100%]"
        onClick={() => setHidePassword(!shouldHidePass)}
      >
        {shouldHidePass ? <EyeOutlined className='fill-slate-500' /> : <EyeInvisibleOutlined />}
      </button>
    </div>
  );
};
