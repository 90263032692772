export const DAY = ['MON', 'TUE', 'WED', 'THU', 'FRI'] as const;
export const TIME = ['MORNING', 'AFTERNOON'] as const;

const createShifts = (days: typeof DAY, times: typeof TIME) => {
  const enumValues: Partial<{
    [k in `${typeof DAY[number]}_${typeof TIME[number]}`]: boolean;
  }> = {};

  for (const day of days) {
    for (const time of times) {
      enumValues[`${day}_${time}`] = false;
    }
  }
  return enumValues;
};

export const SHIFT = createShifts(DAY, TIME);
