import React from 'react';
// @ts-ignore
import classnames from 'clsx';

interface ButtonProps {
  icon?: string;
  renderAs?: string;
  label?: string;
  className?: string;
  type?: 'primary' | 'secondary' | 'rounded';
  children?: any;
  disabled?: boolean;
  onClick?: () => void;
}

function Button({
  icon,
  renderAs,
  label,
  className,
  disabled,
  type = 'primary',
  onClick,
  children,
}: ButtonProps) {
  const ButtonElement = (renderAs || 'button') as React.ElementType;
  const buttonStyles = classnames('button', className, {
    button__primary: type === 'primary',
    button__secondary: type === 'secondary',
    button__rounded: type === 'rounded',
    button__disabled: disabled,
  });
  const id = label ? label.replace(/\s/g, '-') : '';
  return (
    <ButtonElement
      disabled={disabled}
      className={buttonStyles}
      onClick={onClick}
      id={id}
    >
      {icon ? <img src={icon} alt="" className="button__icon" /> : ''}
      {label}
      {children}
    </ButtonElement>
  );
}

export default Button;
