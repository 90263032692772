import { useMutation, useQuery } from '@apollo/client';
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  Pagination,
  Select,
  Table,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormInstance } from 'antd/lib/form';
import clsx from 'clsx';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Loading from '../../components/loading';
import { DEPARTMENT_LIST, RECORD_PER_PAGE } from '../../const';
import { GET_ALL_EMPLOYEE } from '../../graphql/employee';
import { SET_EMPLOYEE } from '../../graphql/user/user';
import EmployeeSearchBar from './../../components/employeeSearchBar';
import Paper from './../../components/paper';
import { EmailInput } from './email-input';
import { PasswordCell } from './password-cell';

const { Option } = Select;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  fullName: string;
  email: string;
  department: string;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  type: 'date' | null | undefined;
  required: boolean;
  handleSave: (record: Item) => void;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  required,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]:
        type === 'date' && record[dataIndex]
          ? moment(record[dataIndex])
          : record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      const value =
        type === 'date' ? values[dataIndex].toISOString() : values[dataIndex];

      toggleEdit();
      handleSave({ ...record, [dataIndex]: value });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required,
            message: `${title} is required.`,
          },
        ]}
      >
        {type === 'date' ? (
          <DatePicker onBlur={save} />
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className={clsx('editable-cell-value-wrap', {
          'text-gray-300': !record[dataIndex],
        })}
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {record[dataIndex]
          ? type === 'date'
            ? new Intl.DateTimeFormat(undefined, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }).format(new Date(record[dataIndex]))
            : children
          : 'N/A'}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

function Employee(props: any) {
  const [allEmployeeRaw, setAllEmployee] = useState<any>([]);
  const [isData, setIsData] = useState<boolean>(false);
  const [pagination, setPage] = useState<any>({});
  const [queryInput, setQueryInput] = useState<any>({
    page: 1,
    limit: 25,
    name: '',
  });

  const {
    loading: loadingEmployee,
    data: allEmployeesData,
    refetch,
  } = useQuery(GET_ALL_EMPLOYEE, {
    variables: queryInput,
    fetchPolicy: 'no-cache',
  });

  const [
    setEmployee,
    { loading: loadingChange, error: err, data: dataSuccess },
  ] = useMutation(SET_EMPLOYEE);

  useEffect(() => {
    if (allEmployeesData) {
      const { getEmployees } = allEmployeesData;
      const pagi = getEmployees.metaData;
      setAllEmployee(getEmployees.data);
      setPage({
        current: queryInput.page,
        pageSize: RECORD_PER_PAGE,
        total: pagi.numOfPage * RECORD_PER_PAGE,
      });
      setIsData(true);
    }
  }, [allEmployeesData, queryInput.page]);

  useEffect(() => {
    if (dataSuccess) {
      const { code } = dataSuccess.updateEmployee;
      if (code === 200) {
        refetch();
      }
    } else {
      console.log(err);
    }
  }, [dataSuccess, err, queryInput, refetch]);

  const handlePaginate = (value: number) => {
    setQueryInput({
      ...queryInput,
      page: value,
    });
    setPage({
      ...pagination,
      current: value,
    });
  };

  const handleSelect = (value: any) => {
    const query = value.length ? value.join('|') : '';
    setQueryInput({
      ...queryInput,
      name: query,
      page: 1,
    });
  };

  const handleOnCheck = (e: CheckboxChangeEvent, ele: any) => {
    setEmployee({
      variables: {
        id: Number(ele.id),
        fullName: ele.fullName,
        activated: !ele.activated,
      },
    });
  };

  const handleSave = (values: any) => {
    const updatedEmployees = allEmployee.map((elm: any) => {
      if (elm.id === values.id) {
        return values;
      } else {
        return elm;
      }
    });

    setAllEmployee(updatedEmployees);
    setEmployee({
      variables: {
        ...values,
        // entryDate: values.entryDate.toISOString(),
        id: Number(values.id),
      },
    });
  };

  const allEmployee = allEmployeeRaw.map((elm: any, idx: number) => ({
    ...elm,
    key: idx,
  }));
  const onChangeSelectDep = (values: any) => {
    setEmployee({ variables: { ...values, id: Number(values.id) } });
  };

  const columnsRaw: any = [
    {
      title: () => (
        <div className="daily__table__title">
          FULL NAME
          <EmployeeSearchBar
            className="daily__table__search"
            handleSelect={handleSelect}
          />
        </div>
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      width: '80%',
      editable: true,
    },
    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      key: 'employeeCode',
      editable: true,
      width: '30%',
    },
    {
      title: 'Entry Date',
      dataIndex: 'entryDate',
      key: 'entryDate',
      type: 'date',
      editable: true,
      required: false,
      width: '30%',
    },
    {
      title: 'Fingerprint Device Id',
      editable: true,
      key: 'deviceUserId',
      dataIndex: 'deviceUserId',
      width: '30%',
      render: (value) => value || 'na',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '30%',
      render: (value: string, record) => {
        return (
          <EmailInput
            id={record.id}
            originalEmail={value}
            fullName={record.fullName}
            activated={record.activated}
            department={record.depratment}
          />
        );
      },
    },
    {
      title: 'Password',
      dataIndex: 'raw_password',
      width: '30%',
      render: (value) => {
        return <PasswordCell password={value} />;
      },
    },
    {
      title: 'Department',
      dataIndex: 'department',
      width: '30%',
      render: (value: string, record: any) => {
        return (
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select a department"
            optionFilterProp="children"
            value={value}
            onChange={(value: any) =>
              onChangeSelectDep({ ...record, department: value })
            }
            filterOption={(input, option: any) => {
              return (
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
          >
            {DEPARTMENT_LIST.map((dep) => (
              <Option key={dep.value} value={dep.value}>
                {dep.name}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'ACTIVE',
      dataIndex: 'activated',
      key: 'activated',
      render: (activated: boolean, ele: any) => (
        <Checkbox
          className="employee__checkbox"
          checked={activated}
          onChange={(e) => handleOnCheck(e, ele)}
        />
      ),
      width: '20%',
    },
  ];

  const columns = columnsRaw.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.type,
        required: col.required ?? true,
        handleSave,
      }),
    };
  });

  const components = {
    body: {
      cell: EditableCell,
      row: EditableRow,
    },
  };

  return (
    <div className="employee">
      <Paper>
        <div className="daily__title">All Employee Status</div>
        {!isData ? (
          <Loading />
        ) : (
          <>
            <Table
              components={components}
              className="employee__table"
              dataSource={allEmployee}
              columns={columns}
              scroll={{ y: 0 }}
              loading={loadingChange || loadingEmployee}
              rowKey={(record) => record.fullName}
              bordered={true}
              pagination={false}
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? 'daily__table-row-light'
                  : 'daily__table-row-dark'
              }
            />
            <Pagination
              defaultCurrent={1}
              current={pagination.current}
              className="daily__pagination"
              total={pagination.total}
              pageSize={25}
              onChange={handlePaginate}
            />
          </>
        )}
      </Paper>
    </div>
  );
}

export default Employee;
