import { createGlobalState } from 'react-hooks-global-state';

const isDrawerOpen = JSON.parse(localStorage.getItem('isDrawerOpen') as any);
const initialState = {
  user: {

  },
  isCloseDrawer: !isDrawerOpen,
  isNotifModal: false,
  contentNotif: '',
};
const globalState = createGlobalState(initialState);

export const useGlobalState = globalState.useGlobalState;
