import {
  CalendarOutlined, ClockCircleOutlined, IdcardOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined, PartitionOutlined,
  ProfileOutlined, UserOutlined
} from '@ant-design/icons';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { DATE_QUERY_FORMAT } from '../const';
import { useGlobalState } from './../state';
import { AdminOnly } from './admin-only';
import Button from './button';

function DrawerMenu() {
  const [isClose, setIsClose] = useGlobalState('isCloseDrawer');
  const defaultDay = moment().format(DATE_QUERY_FORMAT);
  const defaultDayFrom = moment().subtract(6, 'days').format(DATE_QUERY_FORMAT);
  const drawerStyles = classnames('drawer', {
    'drawer--close': isClose,
  });

  const closeDrawer = () => {
    setIsClose(!isClose);
    localStorage.setItem('isDrawerOpen', 'false');
  };

  return (
    <div className={drawerStyles}>
      <div className="drawer__welcome">
        {!isClose && (
          <Button
            onClick={closeDrawer}
            type="rounded"
            className="drawer__close-btn"
          >
            <span className="drawer__left-icon"></span>
          </Button>
        )}
      </div>
      <ul className="drawer__navigate">
        <li className="drawer__item" title="Daily">
          <NavLink
            to={`/daily/${defaultDay}`}
            className="drawer__item__link"
            activeClassName="active"
            isActive={(match, location) => {
              const path = location.pathname.split('/')[1];
              if (path !== 'daily') {
                return false;
              }
              return true;
            }}
          >
            <ClockCircleOutlined className="drawer__item__icon" />
            Daily
          </NavLink>
        </li>

        <li className="drawer__item" title="Range">
          <NavLink
            to={`/range/${defaultDayFrom}--${defaultDay}`}
            className="drawer__item__link"
            activeClassName="active"
            isActive={(match, location) => {
              const path = location.pathname.split('/')[1];
              if (path !== 'range') {
                return false;
              }
              return true;
            }}
          >
            <CalendarOutlined className="drawer__item__icon" />
            Range
          </NavLink>
        </li>

        <AdminOnly>
          <li className="drawer__item" title="Employee">
            <NavLink
              to="/employee"
              className="drawer__item__link"
              activeClassName="active"
              isActive={(match) => {
                if (!match) {
                  return false;
                }
                return true;
              }}
            >
              <IdcardOutlined className="drawer__item__icon" />
              Employee
            </NavLink>
          </li>
        </AdminOnly>

        <AdminOnly>
          <li className="drawer__item" title="Employee">
            <NavLink
              to="/users"
              className="drawer__item__link"
              activeClassName="active"
              isActive={(match) => {
                if (!match) {
                  return false;
                }
                return true;
              }}
            >
              <UserOutlined className="drawer__item__icon" />
              Users
            </NavLink>
          </li>
        </AdminOnly>

        <li className="drawer__item" title="My Profile">
          <NavLink
            to="/my-info"
            className="drawer__item__link"
            activeClassName="active"
            isActive={(match) => {
              if (!match) {
                return false;
              }
              return true;
            }}
          >
            <ProfileOutlined className="drawer__item__icon" />
            My Profile
          </NavLink>
        </li>

        <li className="drawer__item" title="WFH Registration">
          <NavLink
            to="/wfh"
            className="drawer__item__link"
            activeClassName="active"
            isActive={(match) => {
              if (!match) {
                return false;
              }
              return true;
            }}
          >
            <PartitionOutlined className="drawer__item__icon" />
            WFH
          </NavLink>
        </li>
      </ul>
      <p className="drawer__signout" onClick={closeDrawer}>
        {!isClose ? (
          <MenuFoldOutlined className="drawer__item__icon" />
        ) : (
          <MenuUnfoldOutlined className="drawer__item__icon" />
        )}
      </p>
    </div>
  );
}

export default DrawerMenu;
