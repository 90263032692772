import React, { useState, useEffect } from 'react';
// import EClock from './eclock'
interface Props {
  className?: string;
}

export default function Clock({ className }: Props) {
  const [ctime, setCtime] = useState<any>();

  const UpdateTime = () => {
    const time = new Date().toLocaleTimeString();
    setCtime(time);
  };
  useEffect(() => {
    const interval = setInterval(UpdateTime, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className={className}>{ctime}</div>
    </>
  );
}
