import { gql } from '@apollo/client';

export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on User {
    firstName
    lastName
    email
    imageUrl
  }
`;

export const CORE_USER_RESPONSE_FIELDS = gql`
  fragment CoreUserResponseFields on UserResponse {
    status
    code
    message
    user {
      ...CoreUserFields
    }
  }
  ${CORE_USER_FIELDS}
`;
