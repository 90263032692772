import { MyInfo } from 'pages/my-profile';
import { WorkFromHome } from 'pages/work-from-home';
import Daily from './pages/daily';
import Employee from './pages/employee';
import Range from './pages/range';
import Users from './pages/user/users';

const routes = [
  {
    path: '/daily/:date',
    exact: false,
    component: Daily,
    pagename: 'Daily',
  },
  {
    path: '/range/:range',
    exact: false,
    component: Range,
    pagename: 'Range',
  },
  {
    path: '/employee',
    exact: false,
    component: Employee,
    pagename: 'Employee',
  },
  {
    path: '/users',
    exact: false,
    component: Users,
    pagename: 'Users',
  },
  {
    path: '/my-info',
    exact: false,
    component: MyInfo,
    pagename: 'Users',
  },
  {
    path: '/wfh',
    exact: false,
    pagename: 'WFH',
    component: WorkFromHome,
  },
];

export { routes };
