import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as authLibs from './authentication';
import { onError } from '@apollo/client/link/error';

const auth =
  process.env.REACT_APP_AUTH_PROVIDER === 'Standard'
    ? authLibs.standardAuth
    : authLibs.ggAuth;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

import { REACT_APP_GRAPHQL_SERVER } from 'const';

const globalErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const error = data.errors && (data.errors[0] as any);

    if (error && error.message === 'Unauthorized') {
      auth.logout();
      return null;
    }

    return data;
  });
});

const httpLink = createHttpLink({
  uri: REACT_APP_GRAPHQL_SERVER,
});

const authLink = setContext(async (_, { headers }) => {
  let token = null;
  try {
    token = await auth.getToken();
  } catch (err) {
    console.error(err);
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(globalErrorLink).concat(httpLink),
  cache: new InMemoryCache(),
});
