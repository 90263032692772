import React, { useState, useEffect } from 'react';
import Paper from './../components/paper';
import { Table, DatePicker, Pagination, Button } from 'antd';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { GET_RANGE_QUERY, GET_RANGE_EXPORT_ID } from '../graphql/employee';
import { formatDataRange } from '../utils';
import {
  RECORD_PER_PAGE,
  DATE_QUERY_FORMAT,
  DATE_DISPLAY_FORMAT,
  API_ENDPOINT,
} from '../const';
import Loading from '../components/loading';
import EmployeeSearchBar from './../components/employeeSearchBar';
import { useAuth } from 'components/auth';
import { AdminOnly } from 'components/admin-only';
const { RangePicker } = DatePicker;

function Range(props: any) {
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [queryInput, setQueryInput] = useState<any>({
    name: '',
  });
  const [pagination, setPage] = useState<any>({});
  const [dataSource, setDataSource] = useState<any>();
  const [isData, setIsData] = useState<boolean>(false);
  const [getRangeEmployees, { loading, data: dataRange }] = useLazyQuery(
    GET_RANGE_QUERY,
    { fetchPolicy: 'no-cache' }
  );
  const [getRangeExportId, { data: idExport }] =
    useLazyQuery(GET_RANGE_EXPORT_ID);

  useEffect(() => {
    const current = moment();
    let [queryFrom, queryTo] = props.match.params.range.split('--');
    if (
      moment(queryFrom, DATE_QUERY_FORMAT) > moment(queryTo, DATE_QUERY_FORMAT)
    ) {
      props.history.push(`${queryTo}--${queryFrom}`);
      return;
    }

    if (queryTo && current >= moment(queryTo, DATE_QUERY_FORMAT)) {
      setQueryInput((q: any) => ({
        ...q,
        from: queryFrom,
        to: queryTo,
        page: 1,
        limit: RECORD_PER_PAGE,
      }));
    } else {
      props.history.push(`${queryFrom}--${moment().format(DATE_QUERY_FORMAT)}`);
    }
  }, [props.history, props.match.params.range]);

  useEffect(() => {
    if (queryInput && queryInput.from && queryInput.to) {
      getRangeEmployees({ variables: queryInput });
      getRangeExportId({
        variables: {
          from: queryInput.from,
          to: queryInput.to,
        },
      });
    }
  }, [getRangeEmployees, queryInput, getRangeExportId]);

  useEffect(() => {
    if (dataRange) {
      const { getRangeEmployees } = dataRange;
      const newData = formatDataRange(getRangeEmployees.data);
      const pagi = getRangeEmployees.metaData;
      setPage({
        current: queryInput.page,
        pageSize: RECORD_PER_PAGE,
        total: pagi.numOfPage * 25,
      });
      setDataSource(newData);
      setIsData(true);
    }
  }, [dataRange, queryInput]);

  const handleOnCLick = () => {
    props.history.push(`${dateFrom}--${dateTo}`);
  };
  const handleOnChangeDateFrom = (date: any, dateString: any) => {
    if (date) {
      setDateFrom(
        moment(dateString[0], DATE_DISPLAY_FORMAT).format(DATE_QUERY_FORMAT)
      );
      setDateTo(
        moment(dateString[1], DATE_DISPLAY_FORMAT).format(DATE_QUERY_FORMAT)
      );
    } else {
      setDateFrom('');
      setDateTo('');
    }
  };

  const handlePaginate = (value: number) => {
    setPage({
      ...pagination,
      current: value,
    });
    setQueryInput({
      ...queryInput,
      page: value,
    });
  };

  const handleExport = async () => {
    const uri = `${API_ENDPOINT}/employee/export?exportId=${idExport.getRangeExportId.exportId}`;
    window.open(uri);
  };

  const handleSelect = (value: any) => {
    const query = value.length ? value.join('|') : '';
    setQueryInput({
      ...queryInput,
      name: query,
      page: 1,
    });
  };

  const columns = [
    {
      title: () => <div className="daily__table__title">Employee Code</div>,
      dataIndex: 'employeeCode',
      key: 'employeeCode',
      width: '20%',
    },
    {
      title: () => (
        <div className="daily__table__title">
          FULL NAME
          <EmployeeSearchBar
            className="daily__table__search"
            handleSelect={handleSelect}
          />
        </div>
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      width: '40%',
    },
    {
      title: 'PRESENT',
      dataIndex: 'present',
      key: 'present',
      width: '15%',
    },
    {
      title: 'DAYOFF',
      dataIndex: 'dateOff',
      key: 'dateOff',
      width: '15%',
    },
    {
      title: 'LATE',
      dataIndex: 'late',
      key: 'late',
      width: '15%',
    },
    {
      title: 'ABSENT',
      dataIndex: 'absent',
      key: 'absent',
      width: '15%',
    },
    {
      title: 'WORK FROM HOME',
      dataIndex: 'workFromHome',
      key: 'workFromHome',
      width: '15%',
    },
  ];

  return (
    <div className="range">
      <Paper>
        <div className="daily__title">
          {moment(queryInput.from, DATE_QUERY_FORMAT).format(
            DATE_DISPLAY_FORMAT
          )}{' '}
          -{' '}
          {moment(queryInput.to, DATE_QUERY_FORMAT).format(DATE_DISPLAY_FORMAT)}
        </div>
        <div className="daily__utils">
          <div className="daily__utils__left">
            <div className="range__input__date">
              <RangePicker
                onChange={handleOnChangeDateFrom}
                disabledDate={(current) => {
                  if (dateTo) {
                    return (
                      current &&
                      current >= moment(dateTo, 'YYYY-MM-DD').endOf('day')
                    );
                  }
                  return current && current > moment().endOf('day');
                }}
                format={DATE_DISPLAY_FORMAT}
              />
            </div>
            <Button
              type="primary"
              disabled={dateFrom.length && dateTo.length ? false : true}
              onClick={handleOnCLick}
            >
              Go
            </Button>
          </div>
          <AdminOnly>
            <div className="daily__utils__right">
              <Button
                type="primary"
                onClick={handleExport}
                disabled={dataSource && idExport ? false : true}
              >
                Export Excel
              </Button>
            </div>
          </AdminOnly>
        </div>
        {!isData ? (
          <Loading />
        ) : (
          <>
            <Table
              className="range__table"
              dataSource={dataSource}
              columns={columns}
              bordered={true}
              loading={loading}
              scroll={{ y: 0 }}
              rowKey={(record) => record.fullName}
              pagination={false}
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? 'daily__table-row-light'
                  : 'daily__table-row-dark'
              }
            />
            <Pagination
              defaultCurrent={1}
              current={pagination.current}
              className="range__pagination"
              total={pagination.total}
              showSizeChanger={false}
              pageSize={25}
              onChange={handlePaginate}
            />
          </>
        )}
      </Paper>
    </div>
  );
}

export default Range;
